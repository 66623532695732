$(function() {
  /* hamburger button */
  $('.hamburger-button').click(function() {
    $('.content').addClass('active');
    $('.navbar-container').addClass('active');
    $('.scroll-to-top').css('transform', 'translateY(80px)');
  });

  $('.hamburger-close').click(function() {
    $('.content').removeClass('active');
    $('.navbar-container').removeClass('active');
  });

  /* scroll to top */
  $('.scroll-to-top').click(function() {
    $('html, body').animate({
      scrollTop: 0
    }, $(window).scrollTop() / 3);
  });

  /* slider */
  $('.slider-main').slick({
    arrows: false,
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear'
  });

  /* always compact header on mobile */
  if ($(window).width() <= 768) {
    $('header').addClass('active');
  }

  /* scroll event */
  $(window).scroll(function() {
    /* header animation */
    if ($(window).scrollTop() > 0) {
      $('header').addClass('active');
    } else if ($(window).width() > 768) {
      $('header').removeClass('active');
    }

    /* scroll to top animation */
    if ($(window).scrollTop() > 0) {
      $('.scroll-to-top').css('transform', 'translateY(0px)');
    } else {
      $('.scroll-to-top').css('transform', 'translateY(80px)');
    }
  });
});
